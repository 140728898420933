import { useState } from 'react';

const buttonCls = 'text-gray-500 hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium';
const buttonActiveCls = 'text-blue-500 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium';
const mobileButtonCls = 'text-gray-500 hover:text-gray-800 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium';
const mobileButtonActiveCls = 'text-blue-500 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium';

export function O2Navbar({ setFilter, filter }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onClickAll = () => setFilter('all');
  const onClickFalls = () => setFilter('falls');
  const onClickCreek = () => setFilter('creek');
  const onClickWake = () => setFilter('wake');

  return (
    <div>
        <nav className="bg-white dark:bg-gray-800 shadow">
            <div className="max-w-7xl mx-auto px-8">
                <div className="flex items-center justify-between h-16">
                    <div className=" flex items-center">
                        <div className="hidden md:block">
                            <div className="ml-10 flex items-baseline space-x-4">
                                <a className={filter === 'all' ? buttonActiveCls: buttonCls} href="/#" onClick={onClickAll}>
                                    All
                                </a>
                                <a className={filter === 'falls' ? buttonActiveCls: buttonCls} href="/#" onClick={onClickFalls}>
                                    Falls of Neuse
                                </a>
                                <a className={filter === 'creek' ? buttonActiveCls: buttonCls} href="/#" onClick={onClickCreek}>
                                    Brier Creek
                                </a>
                                <a className={filter === 'wake' ? buttonActiveCls: buttonCls} href="/#" onClick={onClickWake}>
                                    Wake Forest
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="block">
                        <div className="ml-4 flex items-center md:ml-6">
                        </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                        <button className="text-gray-800 dark:text-white hover:text-gray-300 inline-flex items-center justify-center p-2 rounded-md focus:outline-none" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                            <svg width="20" height="20" fill="currentColor" className="h-8 w-8" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z">
                                </path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            {isMenuOpen  && (
              <div className="md:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                    <a className={filter === 'all' ? mobileButtonActiveCls: mobileButtonCls} href="/#" onClick={onClickAll}>
                        All
                    </a>
                    <a className={filter === 'falls' ? mobileButtonActiveCls: mobileButtonCls} href="/#" onClick={onClickFalls}>
                        Falls of Neuse
                    </a>
                    <a className={filter === 'creek' ? mobileButtonActiveCls: mobileButtonCls} href="/#" onClick={onClickCreek}>
                        Brier Creek
                    </a>
                    <a className={filter === 'wake' ? mobileButtonActiveCls: mobileButtonCls} href="/#" onClick={onClickWake}>
                        Wake Forest
                    </a>
                </div>
              </div>
            )}
        </nav>
    </div>


  );
}
