import { useState } from 'react';
import { QueryClient, QueryClientProvider } from "react-query";

import { O2Navbar } from './navbar';
import { Studios } from './studios';

const queryClient = new QueryClient();

function App() {
  const [filter, setFilter] = useState('all');

  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <O2Navbar filter={filter} setFilter={setFilter} />
        <Studios filter={filter} />
      </div>
    </QueryClientProvider>
    
  );
}

export default App;
