import { useState, useEffect } from 'react';
import { useQuery } from "react-query";

import { DayCard } from './day-card';
import { formatClasses } from './utilities';

const isProd = process.env.NODE_ENV === 'production';
const api = isProd ? '/classes' : 'http://localhost:3151/classes';


export function Studios({ filter }) {
  const [classes, setClasses] = useState([]);
  

  const { isLoading, error, data } = useQuery("classes", () =>
    fetch(api).then((res) => res.json())
  );

  useEffect(() => {
    if(data) setClasses(formatClasses({ filter, data }));
  }, [data, filter]);

  if (isLoading) return (
    <div className='loader'>
      <div className="loadingio-spinner-ripple-ty7oalkdhu">
        <div className="ldio-3s1v6kkaen2">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );

  if (error) return "An error has occurred: " + error.message;

  return (
    <div className='bg-blue-300 p-2'>
      {classes.map(({class_date, classes }, idx) => {
        return <DayCard key={class_date} date={class_date} classes={classes} isFirst={idx === 0} /> }
      )}
    </div>
  );
}

