import { format } from 'date-fns';
import { google } from 'calendar-link';

const buildDescription = (cls) => {
  return `class: ${cls.classname}
studio: ${cls.studio}
instructor: ${cls.instructorname}
start time: ${cls.starttime}
  `;
}

export function DayCard({ date, classes, isFirst = false }) {

  return (
    <div className={isFirst ? 'pt-10' : 'mt-10'}>
      <div className="rounded-md max-w-4xl mx-auto p-4 space-y-4 shadow-lg bg-white">
          <h3 className="font-semibold">{format(new Date(date), "MMMM do, yyyy")}</h3>
          {classes.map(cls => {

          const event = {
            title: `O2: ${cls.classname} (${cls.studio})`,
            description: buildDescription(cls),
            start: cls._datetime,
            duration: [1, "hour"],
            location: cls._location,
          };

          const googleEventLink = google(event);

            return (
              <div key={cls.id} className='border-t pt-3 mb-2 flex justify-between items-center'>
                <a  href={`https://maps.google.com/?daddr=${cls._location}`} target='_blank' rel="noreferrer">
                  <h3 className="font-semibold capitalize">{cls.classname.toLowerCase()} <span className="font-thin">{cls.starttime}</span></h3>
                  <h3 className="font-thin">{cls.studio} ({cls.room})</h3>
                  <h3 className="font-thin">{cls.instructorname}</h3>
                </a>
                <div className='ml-1'>
                  <a type="button" className="py-1 px-2  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg" href={googleEventLink} target='_blank' rel="noreferrer">
                      Calendar
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
  );
}

