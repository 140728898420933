import { parse } from 'date-fns';

const LOCATION_MAP = {
  'all': null,
  'creek': 'Brier Creek',
  'falls': 'Falls of Neuse',
  'wake': 'Wake Forest',
}

const LOCATION_GOOGLE_MAP = {
  'Raleigh - Brier Creek': '7801 Alexander Promenade Pl, Raleigh, NC 27617',
  'Raleigh - Falls of Neuse': '6150 Falls of Neuse Rd, Raleigh, NC 27609',
  'Wake Forest - The Factory': '1839 S Main St, Wake Forest, NC 27587',
}

export const formatClasses = ({ filter, data }) => {
  let _classes = [...data];

    if(filter && filter !== 'all'){
      const location = LOCATION_MAP[filter];
      _classes = _classes.filter(cls => cls.studio.includes(location));
    }

    let sortedClasses = _classes.reduce((acc, curr) => {
      curr = { ...curr };
      
      const dateIndex = acc.findIndex(date => date.class_date === curr.class_date);
      const rawTimeWithTz = curr.starttime + " -04:00";
      const dateOnly =  curr.class_date.split('T')[0];
      curr._datetime = parse(`${dateOnly} ${rawTimeWithTz}`, "yyyy-MM-d hh:mm aa xxxxx", new Date());

      curr._location = LOCATION_GOOGLE_MAP[curr.studio];

      if(dateIndex === -1) {
        acc.push({ class_date: curr.class_date, classes: [curr] });
      } else {


        acc[dateIndex].classes.push(curr);
      }

      return acc;
    }, []);


    sortedClasses.sort((a,b) => new Date(a.class_date) - new Date(b.class_date));
    sortedClasses.forEach(date => {
      date.classes.sort((a,b) => new Date(a._datetime) - new Date(b._datetime));
    });

    return sortedClasses;
}